import React from "react";
import "./App.css";
import logo from "../src/assets/sitem.png";
import twitter from "../src/assets/twitter.png";
import telegram from "../src/assets/tg.png";

function App() {
  return (
    <div className="bg-[#0B2233] min-h-screen text-white">
      <nav className="flex justify-between items-center p-4">
        <div className="flex items-center space-x-4">
          <img src={logo} alt="Logo" className="h-10" />

          <a
            href="https://www.metis.io/bridge"
            className="text-[#61D2C5] font-semibold"
          >
            Bridge to Metis
          </a>
          <a
            href="https://dexscreener.com/metis/0x483283af193c8b5d84e517f7cb837decd484c519"
            className="text-[#61D2C5] font-semibold"
          >
            Chart
          </a>
        </div>
        <a
          href="https://hermes.maiadao.io/#/swap?outputCurrency=0x21D50e08DdcEa3A059377a14eb46c2E5990Fec38"
          className="bg-[#61D2C5] text-black px-4 py-2 rounded font-black"
        >
          BUY NOW
        </a>
      </nav>

      <main className="text-center p-4">
        <h1 className="text-3xl font-bold mb-2">SITEM($SITEM)</h1>
        <img src={logo} alt="Token Logo" className="mx-auto mb-4 w-80" />
        <div className="text-white">
          <p className="font-bold text-2xl">Tokenomics </p>
          <p className="font-medium">10,000,000,000 $SITEM</p>
          <p className="font-medium">0 allocations</p>
          <p className="font-medium">0 presale</p>
          <p className="font-medium">0 taxes</p>
          <p className="font-medium">0 BS</p>
        </div>
        <p className="font-bold text-2xl mt-4">THE MEMECOIN ON METIS</p>
        <div className="bg-[#61D2C5] w-1/3 rounded-xl mx-auto mt-6">
          <p className="text-black font-bold py-2">
            CA : 0x21D50e08DdcEa3A059377a14eb46c2E5990Fec38
          </p>
        </div>
      </main>

      <footer className="flex justify-center space-x-4 p-4">
        <a href="https://twitter.com/sitem_coin">
          <img
            src={twitter}
            className="font-bold border-white border-2 px-3 py-1 rounded-md h-8"
          />
        </a>
        <a href="https://t.me/Sitem_Portal">
          <img
            src={telegram}
            alt="Telegram"
            className="font-bold border-white border-2 px-3 py-1 rounded-md h-8"
          />
        </a>
        <a
          href="https://hermes.maiadao.io/#/swap?outputCurrency=0x21D50e08DdcEa3A059377a14eb46c2E5990Fec38"
          className="bg-[#61D2C5] px-3 py-1 font-bold text-black rounded-md"
        >
          Buy token
        </a>
        <a
          href="https://dexscreener.com/metis/0x483283af193c8b5d84e517f7cb837decd484c519"
          className="font-bold border-white border-2 px-3 py-1 rounded-md"
        >
          Chart
        </a>
      </footer>
    </div>
  );
}

export default App;
